import ActionSheet from "actionsheet-react"
import type { ActionSheetProps, ActionSheetRef } from "actionsheet-react"
import classNames from "classnames"
import type { Ref, RefObject } from "react"
import React, { useRef, forwardRef, useImperativeHandle } from "react"

import { theme } from "@bounce/theme"

import CrossIcon from "@bounce/assets/icons/Cross.svg"

export type SlideInProps = ActionSheetProps & {
  onOpen?: () => void
  onClose?: () => void
}
export type SlideInRef = ActionSheetRef
export type SlideInHeaderProps = {
  title: string
  slideInRef: RefObject<SlideInRef>
  closeButton?: boolean
  titleClassName?: string
}

const SlideIn = forwardRef(
  ({ onOpen, onClose, ...props }: SlideInProps, ref: Ref<ActionSheetRef | undefined> | undefined) => {
    const actionSheetRef = useRef<ActionSheetRef>(null)

    const _onClose = () => {
      if (onClose) onClose()
    }

    useImperativeHandle(ref, () => ({
      open: () => {
        if (!actionSheetRef.current) return

        actionSheetRef.current.open()
        if (onOpen) onOpen()
      },
      close: () => {
        if (!actionSheetRef.current) return

        actionSheetRef.current.close()
        _onClose()
      },
    }))

    return (
      <ActionSheet
        onClose={_onClose}
        zIndex={theme.zIndex("max")}
        bgTransition="opacity 0.4s ease-in-out, z-index 0.2s ease-in-out"
        sheetTransition="transform 0.2s linear"
        sheetStyle={{
          backgroundColor: "#fff",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
        bgStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.36)",
        }}
        {...props}
        ref={actionSheetRef}
      />
    )
  },
)
SlideIn.displayName = "SlideIn"

const SlideInHeader = ({ title, closeButton = true, slideInRef, titleClassName }: SlideInHeaderProps) => {
  const handleClose = () => {
    if (!slideInRef.current) return

    slideInRef.current.close()
  }

  return (
    <header className="flex flex-row items-center justify-between py-3 shadow-sm">
      <span className={classNames("title5 ml-5 text-black", titleClassName)}>{title}</span>
      {closeButton ? (
        <button
          onClick={handleClose}
          className="mr-3 flex h-9 w-9 items-center justify-center rounded-full bg-white shadow">
          <CrossIcon className="h-5 w-5 text-black" />
        </button>
      ) : (
        <div className="mr-3" />
      )}
    </header>
  )
}

export { SlideInHeader }

export default SlideIn
