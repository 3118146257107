import classNames from "classnames"
import React from "react"

export type FooterProps = {
  children?: React.ReactNode
  className?: string
}

export const Footer = ({ children, className }: FooterProps) => (
  <footer className={classNames("footer", className)}>{children}</footer>
)
