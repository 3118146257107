import classNames from "classnames"
import React, { useRef, useState } from "react"

import { Button } from "./Button"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"

export type DropdownProps = {
  id: string
  label: string | React.ReactNode
  children?: React.ReactNode
  className?: string
  buttonClassName?: string
  pickerClassName?: string
}

export const Dropdown = ({ id, label, children, className, buttonClassName, pickerClassName }: DropdownProps) => {
  const [isOpen, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const isEmpty = React.Children.count(children) === 0

  const onClick = () => setOpen(current => !current)
  const close = () => setOpen(false)

  useOnClickOutside({
    ref,
    handler: close,
  })

  return (
    <div className={classNames("dropdown", className)} ref={ref}>
      <Button id={id} onClick={onClick} label={label} isOpen={isOpen} showIcon={!isEmpty} className={buttonClassName} />
      <div
        onClick={close}
        className={classNames("dropdown__picker", isOpen && !isEmpty ? "flex" : "hidden", pickerClassName)}>
        <div className="dropdown__picker-inner" role="menu" aria-orientation="vertical" aria-labelledby={id}>
          {children}
        </div>
      </div>
    </div>
  )
}
