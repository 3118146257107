import classNames from "classnames"

export type SeparatorProps = {
  direction?: "horizontal" | "vertical"
  thickness?: number
  className?: string
}

const Separator = ({ direction = "horizontal", thickness = 1, className }: SeparatorProps) => (
  <div
    className={classNames(`${direction === "vertical" ? `flex-col` : `flex-row`} bg-neutral-300`, className)}
    style={{ minWidth: thickness, minHeight: thickness }}
  />
)

export default Separator
