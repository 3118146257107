import { Analytics, AppInteractionEvents } from "@bounce/analytics"
import type { CountryCode } from "@bounce/i18n"
import { DEFAULT_LANGUAGE, useLocale } from "@bounce/i18n"

import { config } from "@/config"
import { useUrl } from "@/libs/router"
import type { AlternatePath } from "@/types"
import { setCookie } from "@/utils/cookies"

type LanguagesProps = {
  alternates?: AlternatePath[]
  languages: CountryCode[]
}

type LanguagesValues = {
  handleLanguageChange: (e: React.MouseEvent, value: string) => void
  currentOption: { value: string; label: string }
  languageOptions: { value: string; label: string; href: string; hrefLang: string }[]
}

const getAlternateHref = (alternates: AlternatePath[] = [], language: string) =>
  alternates.find(alternate => alternate.language === language)?.href

export const useLanguages = ({ alternates, languages }: LanguagesProps): LanguagesValues => {
  const { getUrl } = useUrl()
  const { language } = useLocale()

  const languageOptions = Object.values(config.locales)
    .filter(locale => languages.includes(locale.language))
    .map(({ language, friendlyName }) => ({
      value: language,
      label: friendlyName,
      href:
        getAlternateHref(alternates, language) ??
        getUrl({ locale: language, forceLocale: language !== DEFAULT_LANGUAGE }),
      hrefLang: language,
    }))

  const handleLanguageChange = (e: React.MouseEvent, value: string) => {
    e.preventDefault()
    setCookie("NEXT_LOCALE", value)
    Analytics.track(AppInteractionEvents.LocaleChanged)

    const option = languageOptions.find(option => option.value === value)
    if (!option) {
      return
    }

    window.location.href = option.href
  }

  const currentOption = { value: language, label: language.toUpperCase() }

  return { handleLanguageChange, currentOption, languageOptions }
}
