import classNames from "classnames"
import React from "react"

import ChevronDownIcon from "@bounce/assets/icons/ChevronDown.svg"

export type ButtonProps = {
  id: string
  isOpen: boolean
  onClick: () => void
  label: string | React.ReactNode
  showIcon?: boolean
  className?: string
}

export const Button = ({ id, isOpen, onClick, label, showIcon, className }: ButtonProps) => (
  <button
    onClick={onClick}
    className={classNames("dropdown__btn", className)}
    id={id}
    aria-expanded={isOpen}
    aria-haspopup="menu">
    <span className="dropdown__btn-label">{label}</span>
    <ChevronDownIcon
      className={classNames("dropdown__btn-icon", isOpen ? "rotate-180" : "rotate-0", !showIcon && "hidden")}
    />
  </button>
)
