export type AlternatePath = {
  href: string
  language: string
}

export type Breadcrumb = {
  id: string
  name: string
  href: string | null
}

export enum Product {
  LuggageStorage = "luggageStorage",
  PackageAcceptance = "PackageAcceptance",
}
