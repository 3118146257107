import { isSupportedEvent } from "../../helpers/isSupportedEvent"
import type { IntercomClient, IntercomClientConfig } from "../../types"

const isIntercomReady = () => typeof window !== "undefined" && !!window.Intercom

// Since a few user properties are different between the native and web APIs
// we need to sanitize the data to be fully compatible with the javascript API
//  JavaScript API: https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
//  Native API: https://github.com/intercom/intercom-react-native#options-2
const sanitizeOptions = ({ customAttributes, ...options }: any) => {
  const sanitized: any = {}
  for (const key in options) {
    const snake_case = key.replace(/([A-Z])/g, "_$1").toLowerCase()
    sanitized[snake_case] = options[key]
  }

  return { ...sanitized, ...customAttributes }
}

const loadIntercom = ({ appId, hide_default_launcher }: IntercomClientConfig) => {
  if (window.Intercom) return
  ;(function (w: Window & typeof globalThis, d: Document, id: string, s?: HTMLScriptElement, _x?: unknown) {
    const i: any = () => {
      // eslint-disable-next-line prefer-rest-params
      i.c(arguments)
    }
    i.q = []
    i.c = function (args: unknown) {
      i.q.push(args)
    }
    w.Intercom = i
    s = d.createElement("script")
    s.async = true
    s.defer = true
    s.src = "https://widget.intercom.io/widget/" + id
    d.head.appendChild(s)
  })(window, document, appId)

  window.intercomSettings = { hide_default_launcher, app_id: appId }

  if (window.Intercom) {
    window.Intercom("boot", { hide_default_launcher })
  }
}

/**
 * Intercom web implementation
 * @see https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */
const Intercom: IntercomClient = {
  loggedIn: false,
  open: false,
  config: undefined,
  setup(config) {
    this.config = config

    loadIntercom(config)
  },
  updateLanguage(language) {
    if (!isIntercomReady() || !window.intercomSettings) return false

    const newSettings = {
      ...window.intercomSettings,
      language_override: language,
    }

    window.intercomSettings = newSettings

    window.Intercom("boot", newSettings)
  },
  async setLauncherVisibility(visibility) {
    if (!isIntercomReady() || !window.intercomSettings) return false

    // we had to override the settings here because intercom seemingly
    // reloads the configurations on focus, no matter what you passed to the update
    // method (vs. window.Intercom("update", { hide_default_launcher: visibility === "GONE" }))
    window.intercomSettings = {
      ...window.intercomSettings,
      hide_default_launcher: visibility === "GONE",
    }

    window.Intercom("update")

    return true
  },
  async logout() {
    if (!isIntercomReady()) return false

    window.Intercom("shutdown")
    this.loggedIn = false

    return true
  },
  async hideIntercom() {
    if (!isIntercomReady()) return false

    window.Intercom("hide")

    return true
  },
  async present() {
    if (!isIntercomReady()) return false

    window.Intercom("show")

    return true
  },
  async presentSpace(space) {
    if (!isIntercomReady()) return false

    window.Intercom("showSpace", space)

    return true
  },
  async presentMessageComposer(message = "") {
    if (!isIntercomReady()) return false

    window.Intercom("showNewMessage", message)

    return true
  },
  async presentConversation(id) {
    if (!isIntercomReady()) return false

    window.Intercom("showConversation", id)

    return true
  },
  async presentArticles() {
    if (!isIntercomReady()) return false

    window.Intercom("showSpace", "help")

    return true
  },
  async presentHelpCenterCollections(_ids) {
    if (!isIntercomReady()) return false

    // Intercom Web doesn't support presenting collections - we'll just show the help center
    window.Intercom("showSpace", "help")

    return true
  },
  async presentContent(_content) {
    return false
  },
  async presentSurvey(id) {
    if (!isIntercomReady()) return false

    window.Intercom("startSurvey", id)

    return true
  },
  async loginUnidentifiedUser() {
    if (!isIntercomReady()) return false

    window.Intercom("update")

    return true
  },
  async loginUserWithUserAttributes(options) {
    if (!isIntercomReady()) return false

    window.Intercom("update", sanitizeOptions(options))
    this.loggedIn = true

    return true
  },
  async updateUser(options) {
    if (!isIntercomReady()) return false

    window.Intercom("update", sanitizeOptions(options))

    return true
  },
  async handlePushMessage() {
    return false
  },
  async sendTokenToIntercom(_deviceToken) {
    return false
  },
  async logEvent(eventName, metaData) {
    if (!isIntercomReady()) return false
    if (!isSupportedEvent({ supportedEvents: this.config?.supportedEvents, eventName })) return false

    window.Intercom("trackEvent", eventName, metaData)

    return true
  },
  async presentArticle(id) {
    if (!isIntercomReady()) return false

    window.Intercom("showArticle", id)

    return true
  },
  onShow(callback) {
    if (!isIntercomReady()) return

    window.Intercom("onShow", () => {
      this.open = true
      callback()
    })
  },
  onHide(callback) {
    if (!isIntercomReady()) return

    window.Intercom("onHide", () => {
      this.open = false
      callback()
    })
  },
}

export { Intercom, isIntercomReady }
