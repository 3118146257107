type IsSupportedEventOptions = {
  supportedEvents?: string[]
  eventName: string
}

/**
 * Check if an event is supported
 */
export const isSupportedEvent = ({ supportedEvents, eventName }: IsSupportedEventOptions) => {
  // If no supportedEvents are specified, allow all events
  if (!supportedEvents) return true
  // If supportedEvents are specified, only allow events that are in the list
  if (supportedEvents.includes(eventName)) return true

  return false
}
