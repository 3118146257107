import classNames from "classnames"
import React from "react"

export type FooterRowProps = {
  children?: React.ReactNode
  className?: string
}

export const FooterRow = ({ children, className }: FooterRowProps) => (
  <div className={classNames("footer__row", className)}>{children}</div>
)
