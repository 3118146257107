const colors = require("tailwindcss/colors")

const getPrimary = () => {
  const primary = {
    50: "#FAFAFF",
    100: "#F5F6FF",
    200: "#EBEBFF",
    700: "#454CED",
    800: "#353ABD",
    900: "#202473",
  }

  return {
    ...primary,
    DEFAULT: primary[700],
  }
}

const neutral = {
  50: "#FCFDFF",
  100: "#F7F8FC",
  200: "#F0F0F5",
  300: "#DFDFE5",
  400: "#D0D0D9",
  700: "#8E8F9E",
  800: "#60606E",
  900: "#272730",
}

const accents = {
  orange: {
    100: "#FEEEE9",
    200: "#FEDDD3",
    700: "#F75826",
    900: "#661901",
  },
  zest: {
    100: "#FCFEE6",
    200: "#F4F7CD",
    700: "#DEF201",
    900: "#5E6601",
  },
  cranberry: {
    700: "#651B53",
    800: "#48133B",
    900: "#140511",
  },
  lilac: {
    100: "#F1E9FE",
    200: "#E7D8FF",
    700: "#7626F7",
    900: "#280166",
  },
  stone: {
    100: "#FFFBF5",
    200: "#F9EDDC",
    700: "#F7A026",
    900: "#663C01",
  },
}

module.exports = {
  // Tailwind default colors
  blue: colors.blue,
  indigo: colors.indigo,
  pink: colors.pink,
  purple: colors.purple,
  violet: colors.violet,

  // Custom colors
  current: "currentColor",
  transparent: "transparent",
  white: "#fff",
  black: neutral[900],
  gray: {
    25: neutral[50],
    50: neutral[100],
    100: neutral[200],
    200: neutral[300],
    300: neutral[400],
    400: neutral[700],
    500: neutral[800],
  },
  sky: {
    50: "#F0F8FF",
    100: "#E0EFFE",
    700: "#1A7AD2",
  },
  coral: {
    900: "#AC2530",
    700: "#FF4858",
    50: "#FFEDEF",
  },
  mint: {
    900: "#14532D",
    800: "#008755",
    700: "#18FCD3",
    600: "#4AF7CE",
    100: "#DBFEF3",
    50: "#E2FFF8",
  },
  gold: {
    50: "#FCFAE8",
    100: "#F2EFD0",
    200: "BB9037",
    700: "#A97918",
  },
  primary: getPrimary(),
  secondary: colors.orange,
  neutral,
  green: {
    100: "#E6FEF6",
    200: "#CDF7E9",
    700: "#07875A",
  },
  yellow: {
    100: "#FFFBF0",
    200: "#FEF3D4",
    700: "#F8C026",
  },
  red: {
    100: "#FEEBE9",
    200: "#FED6D3",
    700: "#D93B2D",
    800: "#B21709",
    900: "#660901",
  },

  // Accents
  accents,
  ...accents,
}
