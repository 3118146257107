import classNames from "classnames"
import React from "react"

import { Button } from "../../Inputs/Button/Button"

import ChevronUpIcon from "@bounce/assets/icons/ChevronUp.svg"

type FooterBackToTopButtonProps = {
  className?: string
  label: string
}

export const FooterBackToTopButton = ({ label, className }: FooterBackToTopButtonProps) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <Button
      as="button"
      onClick={scrollToTop}
      RightIcon={ChevronUpIcon}
      color="white"
      variant="secondary"
      className={classNames("!text-base !min-h-[50px]", className)}
      iconClassName="!w-4 !h-4">
      {label}
    </Button>
  )
}
