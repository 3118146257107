import { useMemo } from "react"

import { formatCurrency, formatCurrencyWhole } from "./currency"
import { intlNumberFormat } from "./intlNumberFormat"
import { formatNumber } from "./number"
import { useTranslation } from "../libs/react-i18next"

const defaultValues = {
  appRating: {
    value: 4.9,
  },
  bounceShieldProtection: {
    value: 10000,
    currency: "USD",
  },
  numAppReviews: {
    value: 150000,
  },
  numAppReviewsBaseline: {
    value: 400000,
  },
  numAppReviewsVariant: {
    value: 403154,
  },
  numCities: {
    value: 4000,
  },
  numGuides: {
    value: 3000,
  },
  numCustomers: {
    value: 2000000,
  },
  numLocations: {
    value: 13000,
  },
  numBagsStored: {
    value: 2000000,
  },
  luggagePricePerDay: {
    value: 5.9,
    currency: "USD",
  },
  lowestPricePerDay: {
    value: 4.13,
    currency: "USD",
  },
  pricePerPackage: {
    value: 2.5,
    currency: "USD",
  },
} as const

type DefaultValueKey = keyof typeof defaultValues
export type GlobalValueKey = DefaultValueKey | "bounceShieldProtectionShort"

type GlobalValues = Record<GlobalValueKey, string>

export class BounceGlobalValues implements GlobalValues {
  private locale: string

  constructor(locale: string) {
    this.locale = locale
  }

  private formatBasicNumber = (key: DefaultValueKey) => formatNumber(defaultValues[key].value, this.locale)

  get appRating() {
    return this.formatBasicNumber("appRating")
  }

  get bounceShieldProtection() {
    return formatCurrencyWhole(
      defaultValues.bounceShieldProtection.value,
      defaultValues.bounceShieldProtection.currency,
      this.locale,
    )
  }

  get bounceShieldProtectionShort() {
    const { currency, value } = defaultValues.bounceShieldProtection
    const { format } = intlNumberFormat({
      locale: this.locale,
      options: {
        style: "currency",
        currency,
        notation: "compact",
        compactDisplay: "short",
      },
    })

    return format(value)
  }

  get luggagePricePerDay() {
    return formatCurrency(
      defaultValues.luggagePricePerDay.value,
      defaultValues.luggagePricePerDay.currency,
      this.locale,
    )
  }

  get lowestPricePerDay() {
    return formatCurrency(defaultValues.lowestPricePerDay.value, defaultValues.lowestPricePerDay.currency, this.locale)
  }

  get numAppReviews() {
    return this.formatBasicNumber("numAppReviews")
  }

  get numAppReviewsBaseline() {
    return this.formatBasicNumber("numAppReviewsBaseline")
  }

  get numAppReviewsVariant() {
    return this.formatBasicNumber("numAppReviewsVariant")
  }

  get numCities() {
    return this.formatBasicNumber("numCities")
  }

  get numCustomers() {
    return this.formatBasicNumber("numCustomers")
  }

  get numLocations() {
    return this.formatBasicNumber("numLocations")
  }

  get numGuides() {
    return this.formatBasicNumber("numGuides")
  }

  get numBagsStored() {
    const { value } = defaultValues.numBagsStored

    const { format } = intlNumberFormat({
      locale: this.locale,
      options: {
        notation: "compact",
        compactDisplay: "short",
      },
    })

    return format(value)
  }

  get pricePerPackage() {
    return formatCurrencyWhole(defaultValues.pricePerPackage.value, defaultValues.pricePerPackage.currency, this.locale)
  }

  get pricePerPackageDecimal() {
    return formatCurrency(defaultValues.pricePerPackage.value, defaultValues.pricePerPackage.currency, this.locale)
  }
}

export const useBounceGlobalValues = () => {
  const { i18n } = useTranslation()

  return useMemo(() => new BounceGlobalValues(i18n.language), [i18n.language])
}
