import { DEFAULT_LANGUAGE } from "@bounce/i18n"
import type { Locale } from "@bounce/i18n"

/**
 * WARNING: Changing these values will affect the URL structure
 */
const LUGGAGE_STORAGE: Record<Locale["language"], string> = {
  en: "luggage-storage",
  es: "consigna-equipaje",
  fr: "consigne-bagage",
  de: "gepaeckaufbewahrung",
  it: "deposito-bagagli",
  pt: "deposito-bagagem",
  pl: "przechowalnia-bagazu",
}

const TERMS = {
  "luggage-storage": LUGGAGE_STORAGE,
}

export const getPathTranslation = (term: keyof typeof TERMS, language: Locale["language"]) =>
  TERMS[term][language] ?? TERMS[term][DEFAULT_LANGUAGE]
