import NextHead from "next/head"
import React from "react"

import { DEFAULT_LANGUAGE, useBounceGlobalValues, useTranslation } from "@bounce/i18n"
import type { Nullable } from "@bounce/util"

import { useUrl } from "../../libs/router"

export type HeadProps = {
  description?: Nullable<string>
  image?: Nullable<string>
  languages?: string[]
  noIndex?: boolean
  title?: Nullable<string>
  url?: string
  alternates?: React.ReactNode[]
  prefetchUrls?: string[]
}

export const Head = ({
  description,
  image,
  languages = [],
  noIndex,
  title,
  url,
  alternates,
  prefetchUrls,
}: HeadProps) => {
  const { t } = useTranslation()
  const bounceGlobals = useBounceGlobalValues()
  const { getUrl } = useUrl()

  const _description: string =
    description ||
    t(
      "cmp.head.defaultDescriptionV4",
      "Luggage Storage Near Me 24/7 - Store your baggage in {{numLocations}}+ worldwide locations. Bounce is the BEST luggage storage app for finding and booking left luggage & lockers near you!",
      { numLocations: bounceGlobals.numLocations },
    )
  const _title: string = title || t("cmp.head.defaultTitle", "Bounce: Luggage Storage Near Me - Left Luggage & Lockers")
  const _image = image || "/static/thumbnail.png"
  const canonical = url?.split("?")[0]?.split("#")[0]

  return (
    <NextHead>
      <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <title key="title">{_title}</title>
      <meta key="description" name="description" content={_description} />
      {!!canonical && <meta key="url" property="og:url" content={canonical} />}
      {!!canonical && <link key="canonical" rel="canonical" href={canonical} />}
      {/* Twitter */}
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      {!!canonical && <meta key="twitter:site" name="twitter:site" content={canonical} />}
      <meta key="twitter:image" name="twitter:image" content={_image} />
      {/*  Open Graph */}
      <meta key="og:image" property="og:image" content={_image} />
      <meta key="og:title" property="og:title" content={_title} />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:site_name" property="og:site_name" content="Bounce Luggage Storage" />
      {/* Index */}
      {noIndex && <meta key="robots" name="robots" content="noindex,follow" />}
      {noIndex && <meta key="googlebot" name="googlebot" content="noindex,follow" />}
      {/* App links */}
      <meta key="google-play-app" name="google-play-app" content="app-id=com.usebounce" />
      {prefetchUrls?.map(url => <link key={url} rel="prefetch" href={url} />)}
      {/* Alternate language links */}
      {languages.length > 1 &&
        (alternates ?? (
          <>
            {languages.map(language => (
              <link
                key={language}
                rel="alternate"
                href={getUrl({ absolute: true, locale: language })}
                hrefLang={language}
              />
            ))}
            <link rel="alternate" href={getUrl({ absolute: true, locale: DEFAULT_LANGUAGE })} hrefLang="x-default" />
          </>
        ))}
    </NextHead>
  )
}
