import classNames from "classnames"
import React from "react"

import CrossIcon from "@bounce/assets/icons/Cross.svg"
import MenuIcon from "@bounce/assets/icons/Menu.svg"

export type NavbarBurgerButtonProps = {
  label: string
  open: boolean
  onClick?: () => void
  className?: string
}

export const NavbarBurgerButton = ({ label, open, onClick, className }: NavbarBurgerButtonProps) => (
  <button onClick={onClick} className={classNames("navbar__burger-btn", className)} aria-label={label}>
    {open ? <CrossIcon className="size-6 text-white" /> : <MenuIcon className="size-6 text-primary" />}
  </button>
)
