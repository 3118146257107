type CachedFormattedOptions = {
  locale: string
  options: Intl.NumberFormatOptions
}

const formatterCache = new Map<string, Intl.NumberFormat>()

/**
 * Reuse Intl.NumberFormat instances to improve performance
 */
export const intlNumberFormat = ({ locale, options }: CachedFormattedOptions): Intl.NumberFormat => {
  const cacheKey = `${locale}__${JSON.stringify(options)}`
  const formatter = formatterCache.get(cacheKey)

  if (!formatter) {
    const newFormatter = new Intl.NumberFormat(locale, options)
    formatterCache.set(cacheKey, newFormatter)
    return newFormatter
  }

  return formatter
}
