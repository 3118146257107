const config = require("../tailwind.config.js")

type ThemeConfig = {
  spacing: Record<string, string>
  screens: Record<string, string>
  colors: Record<string, string>
  zIndex: Record<string, string>
}
const themeConfig = config.theme as ThemeConfig
const spacing: Record<string, number> = {}
const colors: Record<string, string> = {}

/**
 * Convert the tailwind spacing config to pixels
 */
Object.keys(themeConfig.spacing).forEach(key => {
  const size = themeConfig.spacing[key]
  if (!size) return

  if (size === "px") {
    spacing[key] = 1
    return
  }

  if (size.endsWith("px")) {
    spacing[key] = parseInt(size.replace("px", ""), 10)
    return
  }

  if (size.endsWith("rem")) {
    spacing[key] = parseFloat(size.replace("rem", "")) * 16
  }
})

Object.keys(themeConfig.screens).forEach(key => {
  const size = themeConfig.screens[key]
  if (typeof size !== "string") return

  if (size.endsWith("px")) {
    spacing[`screen-${key}`] = parseInt(size.replace("px", ""), 10)
    return
  }

  if (size.endsWith("rem")) {
    spacing[`screen-${key}`] = parseFloat(size.replace("rem", "")) * 16
  }
})

/**
 * Flatten the tailwind colors config
 */
Object.keys(themeConfig.colors).forEach(key => {
  const color = themeConfig.colors[key]
  if (!color) return

  if (typeof color === "string") {
    colors[key] = color
    return
  }

  Object.keys(color).forEach(vKey => {
    const colorVariant = color[vKey]

    if (vKey === "DEFAULT") {
      colors[key] = colorVariant
      return
    }

    colors[`${key}-${vKey}`] = colorVariant
  })
})

export const theme = {
  /**
   * @example
   * theme.spacing("screen-xs") // 375
   * theme.spacing("4") // 16
   * theme.spacing("invalid-key") // 0
   */
  spacing: (key: string): number => {
    const value = spacing[key]
    if (!value) {
      // eslint-disable-next-line no-console
      console.warn(`[@bounce/theme]: ${key} is an unknown spacing`)
      return 0
    }

    return value
  },
  /**
   * @example
   * theme.color("primary") // "#454CED"
   * theme.color("invalid-key") // "#000"
   */
  color: (key: string): string => {
    const value = colors[key]
    if (!value) {
      // eslint-disable-next-line no-console
      console.warn(`[@bounce/theme]: ${key} is an unknown spacing`)
      return "#000"
    }

    return value
  },
  /**
   * @example
   * theme.zIndex("max") // 2147483647
   * theme.zIndex("10") // 10
   * theme.zIndex("invalid-key") // 0
   */
  zIndex: (key: string): number => {
    const value = themeConfig.zIndex[key]
    if (!value) {
      // eslint-disable-next-line no-console
      console.warn(`[@bounce/theme]: ${key} is an unknown zIndex`)
      return 0
    }

    return parseInt(value, 10)
  },
}
