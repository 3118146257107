import classNames from "classnames"

type FooterContactProps = {
  text?: string
  className?: string
}

export const FooterMention = ({ text, className }: FooterContactProps) => (
  <span className={classNames("footer__mention", className)} data-chromatic="ignore" suppressHydrationWarning>
    {text}
  </span>
)
