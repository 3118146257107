import { useEffect } from "react"

import { Intercom, isIntercomReady } from "../../libs/intercom/intercom"
import type { UseIntercomHook } from "../../types"

/**
 * Initialize intercom
 * Can be optionally deferred for improve performance
 * @see https://developers.intercom.com/installing-intercom/docs/basic-javascript
 */
const useIntercom: UseIntercomHook = ({ appId, defer = false, hide_default_launcher = false, delay }) => {
  useEffect(() => {
    const loadIntercom = () => Intercom.setup({ appId, hide_default_launcher })

    if (delay) {
      // Load after a delay
      setTimeout(loadIntercom, delay)
    } else if (defer) {
      // Load on scroll
      window.addEventListener("scroll", loadIntercom, { once: true })
    } else {
      loadIntercom()
    }

    return () => {
      if (!isIntercomReady()) return undefined

      window.Intercom("shutdown")

      delete window.Intercom
      delete window.intercomSettings
    }
  }, [appId, defer, hide_default_launcher, delay])
}

export { useIntercom }
