import classNames from "classnames"
import React from "react"

const __DEFAULT_ELEMENT__ = "a"

type NavbarLinkOwnProps<E extends React.ElementType = React.ElementType> = {
  as?: E
}

export type NavbarLinkProps<E extends React.ElementType = typeof __DEFAULT_ELEMENT__> = NavbarLinkOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof NavbarLinkOwnProps>

export const NavbarLink = <E extends React.ElementType = typeof __DEFAULT_ELEMENT__>({
  as,
  className,
  ...props
}: NavbarLinkProps<E>) => {
  const Component = as || __DEFAULT_ELEMENT__

  return <Component className={classNames("navbar__link", className)} {...props} />
}
