import i18next from "i18next"

import { intlNumberFormat } from "./intlNumberFormat"

/**
 * @example
 * formatNumber(100000, "en-US") // 100,000
 */
export const formatNumber = (value = 0, locale = i18next.language): string => {
  try {
    return intlNumberFormat({ locale, options: {} }).format(value)
  } catch (error) {
    return value.toString()
  }
}
/**
 * @example
 * formatPercentage(0.2, {locale: "en-US", maxDecimalPlaces: 2}) // 20,00%
 */
export const formatPercentage = (
  value?: number | null,
  opts?: { locale: string; maxDecimalPlaces: number },
): string => {
  const { maxDecimalPlaces, locale = i18next.language } = opts ?? {}
  try {
    return intlNumberFormat({
      locale,
      options: {
        style: "percent",
        maximumFractionDigits: maxDecimalPlaces,
      },
    }).format(value ?? 0)
  } catch (error) {
    return String(value)
  }
}
