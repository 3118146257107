import { RadioGroup } from "@headlessui/react"
import classNames from "classnames"
import React, { useState } from "react"

import type { CountryCode } from "@bounce/i18n"
import { useTranslation } from "@bounce/i18n"
import type { SlideInRef } from "@bounce/web-components"
import { Button, SlideIn, SlideInHeader } from "@bounce/web-components"

import { useLanguages } from "./useLanguages"

import CheckIcon from "@bounce/assets/icons/Check.svg"

export type LanguageSelectorModalProps = {
  languages: CountryCode[]
  languagesRef: React.RefObject<SlideInRef>
}

export const LanguageSelectorModal = ({ languages, languagesRef }: LanguageSelectorModalProps) => {
  const [t] = useTranslation()
  const { handleLanguageChange, currentOption, languageOptions } = useLanguages({ languages })
  const [selectedOption, setSelectedOption] = useState<string>(currentOption.value)

  return (
    <SlideIn ref={languagesRef} className="hidden">
      <SlideInHeader slideInRef={languagesRef} title={t("cmp.generic.navbar.slideInTitle", "Change language")} />
      <RadioGroup
        className="h-[280px] divide-y divide-neutral-100 overflow-scroll"
        onChange={lang => {
          setSelectedOption(lang)
        }}
        defaultValue={selectedOption}>
        {languageOptions.map(lang => (
          <RadioGroup.Option
            key={lang.value}
            value={lang.value}
            className="mx-6 flex h-14 items-center justify-between py-5">
            {({ checked }) => (
              <>
                <span className={classNames("label3", checked && "text-primary")}>{lang.label}</span>
                {checked && <CheckIcon className="h-6 w-6 text-primary-700" />}
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <div className="mb-8 mt-4 px-6">
        <Button
          as="button"
          className="w-full"
          variant="primary"
          onClick={ev => {
            ev.preventDefault()
            languagesRef.current?.close()
            handleLanguageChange(ev, selectedOption)
          }}>
          {t("cmp.generic.navbar.slideInSave", "Save")}
        </Button>
      </div>
    </SlideIn>
  )
}
