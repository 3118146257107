import classNames from "classnames"
import React from "react"

import type { MakeOptional } from "@bounce/util"

import type { LinkProps } from "../Link/Link"
import { Link } from "../Link/Link"

type LinkOrButtonProps = Omit<MakeOptional<LinkProps, "href">, "onClick"> & {
  onClick?: () => void
}

type ListProps = {
  className?: string
  links: LinkOrButtonProps[]
  title: Omit<LinkProps, "href"> & { href?: LinkProps["href"] }
}

export type FooterLinkListProps = {
  className?: string
  links?: ListProps[]
}

const ListTitle = ({ className, href, ...props }: ListProps["title"]) => {
  const As = href ? Link : "span"

  return <As className={classNames(className, "footer__list-title")} href={href ?? "#"} {...props} />
}

const LinkOrButton = ({ href, onClick, children, className, ...props }: LinkOrButtonProps) => {
  if (href) {
    return <Link className={className} href={href} onClick={onClick} children={children} {...props} />
  }

  return <button className={className} onClick={onClick} children={children} />
}

const List = ({ title, links, className }: ListProps) => (
  <div className={classNames(className, "footer__list")}>
    <ListTitle {...title} />
    <div className="footer__list-inner">
      {links.map(({ className, ...props }, key) => (
        <LinkOrButton key={key} className={classNames("footer__list-link", className)} {...props} />
      ))}
    </div>
  </div>
)

export const FooterLinkList = ({ links = [], className }: FooterLinkListProps) => (
  <div className={classNames("footer__link-list", className)}>
    {links.map((props, key) => (
      <List {...props} key={key} />
    ))}
  </div>
)
