import type { Locale } from "@bounce/i18n"
import { DEFAULT_LOCALE, getLocale as getLocaleMaybe } from "@bounce/i18n"

type GetLanguageOptions = {
  locale: string
  fallback?: string
}

export const HOMEPAGE_ONLY_LANGUAGES = ["nl", "cs", "da", "hu"]
export const IN_LANGUAGE_URL_LANGUAGES = ["it"]

/**
 * Get the prismic content language from a locale
 * @example getPrismicLanguage("en") // "en-US"
 */
export const getPrismicLanguage = ({ locale, fallback = DEFAULT_LOCALE }: GetLanguageOptions) => {
  return getLocaleMaybe("language", locale)?.locale ?? fallback
}

export const getLocale = (...[key, value, ...rest]: Parameters<typeof getLocaleMaybe>): Locale => {
  const locale = getLocaleMaybe(key, value, ...rest)

  if (!locale) {
    throw new Error(`Locale not found for ${key}=${value}`)
  }

  return locale
}
