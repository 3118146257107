export enum InteractionEvents {
  // General
  InternalLinkPressed = "Internal Link Pressed",
  // FAQ interaction events
  FAQItemPressed = "FAQ Item Pressed",
  // Luggage storage search bar
  LuggageStorageSearchBarSearchPressed = "Luggage Storage Search Bar Search Pressed",
  LuggageStorageSearchBarLocationSuggestionPressed = "Luggage Storage Search Bar Location Suggestion Pressed",
  // Package Acceptance
  PackagesSearchBarSearchPressed = "Packages Search Bar Search Pressed",
  PackagesSearchBarNearMePressed = "Packages Search Bar Near Me Pressed",
  PackageAcceptanceExplainerVideoPlayed = "Package Acceptance Explainer Video Played",
  PackageAcceptancePromoBannerClicked = "Package Acceptance Promo Banner Clicked",
  // Menu
  MenuMyBookingPressed = "Menu My Booking Pressed",
  MenuMyPickupPointsPressed = "Menu My Pickup Points Pressed",
  // Featured Stores
  FindClosestLocationPressed = "Find Closest Location Pressed",
  FeaturedStoresStoreCardPressed = "Featured Stores Store Closest Pressed",
  // Events interaction events
  EventCopiedDiscountCode = "Event Copied Discount Code",
  // App download
  DownloadTheAppPressed = "Download The App Pressed",
  PressedAppStoresImages = "Pressed App Stores Images",
  // HomePage Next
  CheckInDateChanged = "Check In Date Changed",
  NumberOfBagsChanged = "Number Of Bags Changed",
  // Reviews
  NextReviewPressed = "Next Review Pressed",
  ReviewCardPressed = "Review Card Pressed",
  // Poi Slider
  NextPoiPressed = "Next Poi Pressed",
  PoiCardPressed = "Poi Card Pressed",
  // Store Suggestions
  NextStoreSuggestionPressed = "Next Store Suggestion Pressed",
  // WhyBounce
  NextWhyBounceValuePropPressed = "Next WhyBounce Value Prop Pressed",
  // Footer
  SocialLinkPressed = "Social Link Pressed",
  SectionViewed = "Section Viewed",
  // Guides
  GuideLuggageStorageBannerPressed = "Guide Luggage Storage Banner Pressed",
  NextGuidePressed = "Next Guide Pressed",
  // Events
  SeeAllEventsPressed = "See All Events Pressed",
  // Luggage Storage Product Banner
  LuggageStorageProductBannerCTAPressed = "Luggage Storage Product Banner CTA Pressed",
  // Luggage Storage Newsletter Banner
  LuggageStorageNewsletterSignedUp = "Luggage Storage Newsletter - Signed Up",
  LuggageStorageNewsletterBookNowCtaPressed = "Luggage Storage Newsletter - Book Now CTA Pressed",
  // Luggage Storage App Sticky Banner
  LuggageStorageAppStickyBannerDisplayed = "Luggage Storage App Sticky Banner Displayed",
  LuggageStorageAppStickyBannerCTAPressed = "Luggage Storage App Sticky Banner CTA Pressed",
  LuggageStorageAppStickyBannerDismissed = "Luggage Storage App Sticky Banner Dismissed",
}
