import React from "react"

import logo from "@bounce/assets/images/logo/logo-with-text-white.svg?url"
import { useTranslation } from "@bounce/i18n"
import {
  Footer as BaseFooter,
  FooterBackToTopButton,
  FooterLinkList,
  FooterMention,
  FooterRow,
  PNImage,
} from "@bounce/web-components"

import type { StoreContactProps } from "./StoreContact"
import { StoreContact } from "./StoreContact"
import { useLinks } from "./useLinks"

export type FooterProps = {
  storeContact?: StoreContactProps["storeContact"]
  className?: string
}

const CURRENT_YEAR = new Date().getFullYear()

export const Footer = ({ storeContact, className }: FooterProps) => {
  const { t } = useTranslation()
  const links = useLinks()

  return (
    <BaseFooter className={className}>
      <FooterRow>
        <FooterLinkList links={links} className="mt-12" />
      </FooterRow>
      <FooterRow>
        <PNImage src={logo} width={127} height={28} alt="Bounce" className="mt-10 h-auto w-full object-contain" />
      </FooterRow>
      <FooterRow className="mt-11 !max-w-none items-center justify-center border-t border-white pb-8 pt-11">
        <FooterBackToTopButton label={t("cmp.backToTop.cta", "Back to top")} className="mb-12" />
        <StoreContact storeContact={storeContact} className="mb-1" />
        <FooterMention text={`© ${CURRENT_YEAR} Bounce, Inc.`} />
      </FooterRow>
    </BaseFooter>
  )
}
