const containerQueries = require("@tailwindcss/container-queries")
const { zIndex } = require("tailwindcss/defaultTheme")
const plugin = require("tailwindcss/plugin")
const contentVisibility = require("tailwindcss-content-visibility")

const colors = require("./colors/colors")
const fontFamily = require("./fontFamily/fontFamily")
const screens = require("./screens/screens")
const shadow = require("./shadow/shadow")
const spacing = require("./spacing/spacing")
const spacingUtilities = require("./spacing/spacingUtilities")

module.exports = {
  content: [],
  theme: {
    fontFamily,
    spacing,
    screens,
    zIndex: {
      ...zIndex,
      1: "1",
      2: "2",
      max: "2147483647",
    },
    colors,
    extend: {
      boxShadow: {
        xs: "0px 8px 16px 0px #0810C60D",
      },
    },
  },
  plugins: [
    plugin(({ addUtilities }) => addUtilities(shadow)),
    plugin(({ addUtilities }) => addUtilities(spacingUtilities)),
    contentVisibility,
    containerQueries,
  ],
}
