const { screens } = require("tailwindcss/defaultTheme")

const md = `@media (min-width: ${screens.md})`
const xl = `@media (min-width: ${screens.xl})`

module.exports = {
  ".w-section": {
    width: "94px",
    [md]: {
      width: "113px",
    },
    [xl]: {
      width: "150px",
    },
  },
  ".py-section": {
    "padding-top": "94px",
    "padding-bottom": "94px",
    [md]: {
      "padding-top": "113px",
      "padding-bottom": "113px",
    },
    [xl]: {
      "padding-top": "150px",
      "padding-bottom": "150px",
    },
  },
  ".my-section": {
    "margin-top": "94px",
    "margin-bottom": "94px",
    [md]: {
      "margin-top": "113px",
      "margin-bottom": "113px",
    },
    [xl]: {
      "margin-top": "150px",
      "margin-bottom": "150px",
    },
  },
  ".pt-section": {
    "padding-top": "94px",
    [md]: {
      "padding-top": "113px",
    },
    [xl]: {
      "padding-top": "150px",
    },
  },
  ".mt-section": {
    "margin-top": "94px",
    [md]: {
      "margin-top": "113px",
    },
    [xl]: {
      "margin-top": "150px",
    },
  },
  ".gap-y-section": {
    "row-gap": "94px",
    [md]: {
      "row-gap": "113px",
    },
    [xl]: {
      "row-gap": "150px",
    },
  },
  ".pb-section": {
    "padding-bottom": "94px",
    [md]: {
      "padding-bottom": "113px",
    },
    [xl]: {
      "padding-bottom": "150px",
    },
  },
  ".mb-section": {
    "margin-bottom": "94px",
    [md]: {
      "margin-bottom": "113px",
    },
    [xl]: {
      "margin-bottom": "150px",
    },
  },
}
