import classNames from "classnames"
import type { SVGProps } from "react"
import React from "react"

import type { LinkProps } from "../Link/Link"
import { Link } from "../Link/Link"

export type NavbarBrandProps = {
  className?: string
  Logo: React.FC<SVGProps<SVGElement>>
} & LinkProps

export const NavbarBrand = ({ Logo, className, ...props }: NavbarBrandProps) => (
  <Link className={classNames("navbar__brand", className)} {...props}>
    <Logo className="navbar__brand-logo" />
  </Link>
)
