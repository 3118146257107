import type { JwtPayload } from "jwt-decode"
import jwtDecode from "jwt-decode"

import { Persistency, PersistencyKeys } from "@bounce/persistency"

import type { KindOfUser } from "./analytics"

type DecodedToken = {
  account_id?: string
  account_role?: string
  role?: string
  user_id?: string
}

export type User = {
  kind?: KindOfUser
} & DecodedToken

const getKindOfUser = (account: DecodedToken | null): KindOfUser => {
  if (account?.account_id) return "partner"
  if (account?.user_id) return "customer"
  return "unknown"
}

/**
 * Retrieves the user information from the authentication token stored in the cookie.
 */
export const getUser = async (): Promise<User | null> => {
  const token = await Persistency.getCookie(PersistencyKeys.AUTH_TOKEN_KEY)
  if (!token) return null

  const stringifyPayload = jwtDecode<JwtPayload>(token).sub
  if (!stringifyPayload) return null
  const payload = JSON.parse(stringifyPayload)

  return {
    ...payload,
    kind: getKindOfUser(payload),
  }
}
