import classNames from "classnames"
import React from "react"

export type NavbarMobileProps = {
  className?: string
  children?: React.ReactNode
}

export const NavbarMobile = ({ className, children }: NavbarMobileProps) => (
  <div className={classNames("navbar__mobile", className)}>
    <nav className="navbar__mobile-inner">{children}</nav>
  </div>
)
