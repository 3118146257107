import { useMemo } from "react"

import { getLocale } from "../helpers/locale"
import { useTranslation } from "../libs/react-i18next"
import type { Locale } from "../types"

/**
 * Get the current locale
 * @example
 * const locale = useLocale()
 * // {
 * // "locale": "en-US",
 * // "friendlyName": "English",
 * // "active": true,
 * // "region": "NORTH_AMERICA",
 * // "language": "en",
 * // "weekStartsOn": "SUN",
 * // "countryCode": "US",
 * // "distanceSystem": "IMPERIAL"
 * // }
 */
export const useLocale = (): Locale => {
  const { i18n } = useTranslation()

  const locale = useMemo(() => {
    const language = (i18n.language as string | undefined) ?? "en"
    const isALanguage = language.length === 2

    const locale = getLocale(isALanguage ? "language" : "locale", language)
    return locale
  }, [i18n.language])

  if (!locale) throw new Error("[@bounce/i18n] Invalid locale")
  return locale
}
