import type { LinkProps } from "next/link"
import type { AnchorHTMLAttributes } from "react"
import React from "react"

import { Link } from "@bounce/web-components"

type SafeLinkProps = LinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & {
    children?: React.ReactNode
  }

/**
 * SafeLink Component
 *
 * This component extends Next.js Link functionality with additional safety features.
 *
 * @component
 * @param {SafeLinkProps} props - The props for the SafeLink component.
 *
 * @description
 * The SafeLink component automatically sets the 'rel' attribute based on the destination URL:
 * - If the URL matches the App URL pattern (/s/ or /s?), it sets rel="nofollow".
 * - For all other URLs, it doesn't set a 'rel' attribute, allowing default follow behavior.
 *
 * This approach helps in:
 * 1. Preventing search engines from following internal app links, which are not indexed.
 * 2. Maintaining normal behavior for marketing-related links.
 *
 * @example
 * <SafeLink href="/s/some-app-route">App Link</SafeLink>
 * <SafeLink href="/about-us">Normal Link</SafeLink>
 */
const SafeLink: React.FC<SafeLinkProps> = ({ href, children, ...props }) => {
  const appUrlRegex = /\/s[\/|\?]/
  const isAppUrl =
    (typeof href === "string" && href.match(appUrlRegex)) ||
    (typeof href === "object" && href.pathname && href.pathname.match(appUrlRegex))
  const rel = isAppUrl ? "nofollow" : undefined

  return (
    <Link href={href} {...props} rel={rel}>
      {children}
    </Link>
  )
}

export default SafeLink
