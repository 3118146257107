module.exports = {
  "shadow-sm": {
    "box-shadow": "rgba(0, 0, 0, 0.03) 1px 1px 1px",
  },
  shadow: {
    "box-shadow": "rgba(0, 0, 0, 0.07) 1px 1px 1px",
  },
  "shadow-md": {
    "box-shadow": "rgba(0, 0, 0, 0.13) 1px 1px 3px",
  },
  "shadow-lg": {
    "box-shadow": "rgba(0, 0, 0, 0.15) 1px 1px 8px",
  },
  "shadow-xl": {
    "box-shadow": "rgba(0, 0, 0, 0.19) 1px 1px 20px",
  },
  "shadow-2xl": {
    "box-shadow": "rgba(0, 0, 0, 0.25) 1px 1px 30px",
  },
  "shadow-none": {
    "box-shadow": "none",
  },
}
