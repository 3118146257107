import React from "react"

import { useTranslation } from "@bounce/i18n"
import { Dropdown, DropdownItem, Link } from "@bounce/web-components"

import { Product } from "../../../types"

export type ProductSelectorProps = {
  value?: Product
}

export const ProductSelector = ({ value }: ProductSelectorProps) => {
  const { t } = useTranslation()

  const products = [
    {
      id: Product.LuggageStorage,
      label: t("cmp.generic.navbar.productSelector.luggageStorage", "Luggage Storage"),
      href: "/",
    },
    {
      id: Product.PackageAcceptance,
      label: t("cmp.generic.navbar.productSelector.packagePickupV2", "Package pickup"),
      href: "/packages",
    },
  ]

  const currentProduct = products.find(product => product.id === value)

  return (
    <Dropdown id="product-selector" label={currentProduct?.label}>
      {products.map(({ id, href, label }) => (
        <DropdownItem as={Link} key={id} href={href}>
          {label}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}
