import classNames from "classnames"
import React from "react"

const __DEFAULT_ELEMENT__ = "a"

type DropdownItemOwnProps<E extends React.ElementType = React.ElementType> = {
  as?: E
}

export type DropdownItemProps<E extends React.ElementType = typeof __DEFAULT_ELEMENT__> = DropdownItemOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof DropdownItemOwnProps>

export const DropdownItem = <E extends React.ElementType = typeof __DEFAULT_ELEMENT__>({
  as,
  className,
  ...props
}: DropdownItemProps<E>) => {
  const Option = as || __DEFAULT_ELEMENT__

  return <Option {...props} className={classNames("dropdown__item", className)} />
}
