import type { PrismicNextImageProps } from "@prismicio/next"
import { PrismicNextImage } from "@prismicio/next"
import type { ImageProps as NextImageProps } from "next/image"
import NextImage from "next/image"
import React from "react"

import { isObject } from "@bounce/util"

export type PNImageProps = Omit<PrismicNextImageProps, "field" | "alt"> & {
  src: NextImageProps["src"] | PrismicNextImageProps["field"]
  alt?: string | null
  fallbackBlurDataURL?: string
}

const isNextImageSrc = (src: unknown): src is NextImageProps["src"] => {
  if (typeof src === "string") return true

  if (isObject(src) && typeof src.src === "string") return true
  if (isObject(src) && isObject(src.default) && typeof src.default.src === "string") return true

  return false
}

/**
 * Image component that supports Prismic image fields and next image src.
 */
export const PNImage = ({ src, alt, fallbackAlt = "", fallbackBlurDataURL, imgixParams, ...props }: PNImageProps) => {
  if (!src) return null

  if (isNextImageSrc(src)) {
    return <NextImage src={src} alt={alt || fallbackAlt} {...props} />
  }

  // Overwrite alt from the field if alt is provided
  const field = {
    ...src,
    alt: alt || src.alt || null,
  } as PrismicNextImageProps["field"]

  // Use fallback blur data url if blur data url is not provided
  const blurDataURL = props.blurDataURL || fallbackBlurDataURL

  return (
    <PrismicNextImage
      field={field}
      fallbackAlt={fallbackAlt}
      imgixParams={imgixParams}
      blurDataURL={blurDataURL}
      // Ignore lazy loaded images for chromatic visual regression testing
      data-chromatic={props.loading !== "eager" ? "ignore" : undefined}
      {...props}
    />
  )
}
