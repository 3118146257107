import React from "react"

import { Link, NavbarLink, Separator, NavbarDesktop as BaseNavbarDesktop } from "@bounce/web-components"

import { LanguageSelector } from "./LanguageSelector/LanguageSelector"
import { ProductSelector } from "./ProductSelector"
import type { MobileAndDesktopProps } from "./types"
import type { Product } from "@/types"

export type NavbarDesktopProps = {
  authenticated: boolean
  product: Product
} & MobileAndDesktopProps

export const NavbarDesktop = ({
  alternates,
  authenticated,
  className,
  languages,
  links,
  product,
}: NavbarDesktopProps) => {
  const { account, auth, desktop } = links
  const userLink = authenticated ? account : auth

  return (
    <BaseNavbarDesktop className={className}>
      {desktop.map((props, key) => (
        <NavbarLink as={Link} key={key} {...props} />
      ))}
      <Separator direction="vertical" className="h-5" />
      <NavbarLink as="a" rel="nofollow" {...userLink} />
      <LanguageSelector alternates={alternates} languages={languages} />
      <ProductSelector value={product} />
    </BaseNavbarDesktop>
  )
}
