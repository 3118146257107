import { useState, useEffect, useCallback, useRef } from "react"

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
export function useUpdateEffect(effect: () => void, dependencies: any[] = []) {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      return effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

/**
 * A custom useEffect hook that only triggers on mount
 */
export function useOnMountEffect(effect: () => void) {
  useEffect(() => {
    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

/**
 * Web only: Return true after the page becomes interactive
 */
export function useAfterInteractive(interactions = ["scroll", "keydown", "click", "touchstart", "mouse"]) {
  const [hasInteract, setHasInteract] = useState(false)

  const onInteract = useCallback(() => setHasInteract(true), [])

  useEffect(() => {
    if (window.scrollY) {
      onInteract()
      return
    }

    interactions.map(interaction => {
      window.addEventListener(interaction, onInteract, { once: true })
    })

    return () => {
      interactions.map(interaction => window.removeEventListener(interaction, onInteract))
    }
  }, [interactions, onInteract])

  return hasInteract
}

/**
 * Web only: get the scroll position
 */
export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const updatePosition = () => {
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", updatePosition)
    updatePosition()

    return () => window.removeEventListener("scroll", updatePosition)
  }, [])

  return scrollPosition
}

export function usePreviousValue<TValue>(value: TValue): TValue | undefined {
  const ref = useRef<TValue>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

/**
 * Returns a tuple containing a boolean value and a function to toggle it.
 * @param initialValue - The initial value of the boolean.
 * @returns A tuple containing the boolean value and a function to toggle it.
 */
export const useBoolean = (initialValue = false) => {
  const [value, setValue] = useState(initialValue)

  /**
   * Pass a boolean to set a specific value.
   * Pass anything else (or no argument) to toggle the current value.
   * @param passedArg - The argument to pass to the toggle function.
   */
  const toggleValue = useCallback((passedArg?: unknown) => {
    if (typeof passedArg === "boolean") {
      setValue(passedArg)
      return
    }

    setValue(v => !v)
  }, [])

  return [value, toggleValue] as const
}

type AsyncState<TData> =
  | { status: "idle"; data: null; error: null }
  | { status: "loading"; data: null; error: null }
  | { status: "success"; data: TData; error: null }
  | { status: "error"; data: null; error: Error }

/**
 * A custom hook that handles asynchronous functions and returns the state of the promise.
 * @template TData The type of the data returned by the promise.
 * @param {() => Promise<TData>} asyncFunction The asynchronous function to be executed.
 * @returns {AsyncState<TData>} The state of the promise.
 */
export const useAsync = <TData = unknown>(asyncFunction: () => Promise<TData>) => {
  const [state, setState] = useState<AsyncState<TData>>({ status: "idle", error: null, data: null })

  useEffect(() => {
    setState({ status: "loading", error: null, data: null })

    asyncFunction()
      .then(data => setState({ status: "success", error: null, data }))
      .catch(error => setState({ status: "error", data: null, error }))
  }, [asyncFunction])

  return state
}
