import React from "react"

import { Dropdown, DropdownItem } from "@bounce/web-components"

import { useLanguages } from "./useLanguages"
import type { AlternatePath } from "@/types"

export type LanguageSelectorProps = {
  alternates?: AlternatePath[]
  languages: string[]
}

export const LanguageSelector = ({ alternates, languages }: LanguageSelectorProps) => {
  const { handleLanguageChange, currentOption, languageOptions } = useLanguages({ alternates, languages })

  const options = languageOptions.filter(option => option.value !== currentOption.value)

  return (
    <Dropdown id="language-selector" label={currentOption.label}>
      {options.map(option => (
        <DropdownItem
          as="a"
          key={option.value}
          onClick={(e: React.MouseEvent) => handleLanguageChange(e, option.value)}
          {...option}>
          {option.label}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}
